import { Injectable, inject, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment.dev';
import { UserInfo, UserPreferences } from '@interfaces/dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public userInfo = signal<UserInfo | null>(null);
  public showWelcomeBanner = signal<boolean>(true);
  public preferences = signal<UserPreferences | null>(null);

  private readonly _http = inject( HttpClient );
  private readonly _endpoint = environment.apiURL;
  private countryNameToCode: { [key: string]: string } = {
    'Brazil': 'BR',
    'Colombia': 'CO',
    'Peru': 'PE',
    'Ecuador': 'EC',
    'Bolivia': 'BO',
    'Venezuela': 'VE',
    'Guyana': 'GY',
    'Suriname': 'SR'
  };

  constructor() { }

  public getYearSectorialDataFiltered(startYear: number, endYear: number, sectors: string[], countries: string[], statuses: string[]): Observable<any> {
    const url = `${this._endpoint}GetYearSectorialDataFiltered`;
    const countryCodes = countries.map(country => this.countryNameToCode[country] || country);
    
    const body = {
      startYear,
      endYear,
      sectors,
      countries: countryCodes,
      statuses: statuses
    };

    return this._http.post(url, body);
  }

  public getLeastFundedSectors(startYear: number, endYear: number, countries: string[], statuses: string[]): Observable<any> { 
    const countryCodes = countries.map(country => this.countryNameToCode[country] || country);
    return this._http.get<any[]>(`${this._endpoint}GetLeastFundedSectors?startYear=${startYear}&endYear=${endYear}&countryCodes=${countryCodes}&statuses=${statuses}`);
  }

  public setUserPreferences(preferences: any, isEmpty?: boolean){
    this.preferences.set({ ...preferences, isEmpty });
  }

  public getPreferencesFromUser(): UserPreferences | null {
    return this.preferences();
  }

  public userInformation(user: any) {
    this.userInfo.set(user);
  }

  public getuserInformation(): UserInfo | null {
    return this.userInfo();
  }

  public updateWelcomeBanner(value: boolean) {
    this.showWelcomeBanner.set(value);
  }

  public createPreferences(preferencesData: UserPreferences, idToken: string): Observable<any> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${idToken}`
    });
    return this._http.post<any>(`${this._endpoint}CreateUserPreferences`, preferencesData, { headers });
  }

  public getUserPreferences(email: string, idToken: string): Observable<string> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${idToken}`
    });
    return this._http.get<any>(`${this._endpoint}GetUserPreferences?email=${email}`, { headers });
  }

  public editUserPreferences(preferencesData: UserPreferences, idToken: string): Observable<any> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${idToken}`
    });
    return this._http.post<any>(`${this._endpoint}EditUserPreferences`, preferencesData, { headers });
  }

  public getSectorData(startYear: number, endYear: number, countries: string, sectors: string): Observable<any> { 
      return this._http.get<any[]>(`${this._endpoint}GetYearSectorialData?startYear=${startYear}&endYear=${endYear}&countries=${countries}&sectors=${sectors}`);
  }

  public getInstrumentsFiltered(startYear: number, endYear: number, countries: string[], sectors: string[], instruments: string[], statuses: string[]): Observable<any> {
    const url = `${this._endpoint}GetInstrumentsFiltered`;
    const countryCodes = countries.map(country => this.countryNameToCode[country] || country);
    
    const body = {
      startYear,
      endYear,
      countries: countryCodes,
      sectors,
      instruments,
      statuses
    };
    return this._http.post(url, body);
  }

  public getAgenciesByCountry(countries: string[]): Observable<any> {
    const countryCodes = countries.map(country => this.countryNameToCode[country] || country);
    return this._http.get<any[]>(`${this._endpoint}GetAgenciesByCountry?countryCodes=${countryCodes}`);
  }

  public getProjectSectorPercentage(countryName: string, statuses: string): Observable<any> {
    const countryCode = this.countryNameToCode[countryName];
    
    if (!countryCode) {
        console.error(`Country name "${countryName}" not found in mapping.`);
        return new Observable();
    }

    return this._http.get<any[]>(`${this._endpoint}GetProjectSectorPercentage?country=${countryCode}&statuses=${statuses}`);
  }

  public getAgenciesByHq(countries: string[]): Observable<any> {
    return this._http.get<any[]>(`${this._endpoint}GetAgenciesByHq?hqCountries=${countries}`);
  }

  public getAgenciesProjectsByCountry(agencyName: string): Observable<string> {
    return this._http.get<any>(`${this._endpoint}GetAgencyProjectByCountryCount?agency_name=${agencyName}`);
  }

  public getUserFavoriteProjects(idToken: string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${idToken}`
    });
    return this._http.get<any>(`${this._endpoint}GetUserFavoriteProjects`, {headers});
  }

  public toggleUserFavoriteProject(projectId: string, idToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${idToken}`
    });
  
    return this._http.post<any>(
      `${this._endpoint}ToggleUserFavoriteProject`,
      { projectId }, 
      { headers }
    );
  }

  public getUserFavoriteAgencies(idToken: string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${idToken}`
    });
    return this._http.get<any>(`${this._endpoint}GetUserFavoriteAgencies `, {headers});
  }
  

    public toggleUserFavoriteAgencies(organizationId: string, idToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${idToken}`
    });
  
    return this._http.post<any>(
      `${this._endpoint}ToggleUserFavoriteAgency`,
      { organizationId }, 
      { headers }
    );
  }

  
}
